import { TemplateElement, html, defineElement, classMap, when, choose } from '@webtides/element-js';
import style from './page-teasers.css';
import i18n from '../../../../utils/i18n.js';
import { formatDate } from '../../../../utils/dates.js';
import '../../../slider/slider.js';

/**
 * @property {string} layoutVariant
 * @property {array} pages
 */
export default class PageTeasers extends TemplateElement {
    constructor() {
        super({ styles: [style] });
    }

    properties() {
        return {
            layoutVariant: 'list', // list || slider
            pages: [],
        };
    }

    template() {
        return html` ${this.renderList(this.layoutVariant, this.pages)} `;
    }

    renderList(listType, pages) {
        return choose(listType, {
            list: html`<div class="list flex flex-col gap-64 lg:gap-64">
                ${pages.map((page, index) => this.renderTeaser('list', page, index))}
            </div>`,
            slider: html`<mb-slider
                class="block"
                pagination="false"
                slides-per-view="1"
                breakpoints="${JSON.stringify({
                    700: {
                        slidesPerView: this.pages.length > 2 ? 2.25 : this.pages.length,
                        spaceBetween: 24,
                    },
                    1000: {
                        slidesPerView: this.pages.length > 2 ? 2.4 : this.pages.length,
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: this.pages.length > 2 ? 3 : this.pages.length,
                        spaceBetween: 40,
                    },
                })}"
            >
                ${pages.map(
                    (page, index) => html`
                        <div slot="slide-${index}" class="h-full">${this.renderTeaser('slider', page, index)}</div>
                    `,
                )}
            </mb-slider>`,
        });
    }

    renderTeaser(teaserType, page, index) {
        const isEven = index % 2 !== 0;
        return choose(teaserType, {
            list: html`<div class="list-teaser flex items-center flex-col md:flex-row gap:16 md:gap-24">
                ${when(
                    page.image,
                    html`
                        <img
                            src="${page.image?.link}"
                            alt="${page.image?.altText}"
                            class="w-full aspect-video object-cover md:w-1/2 mb-8 md:mb-0 ${when(
                                isEven,
                                'md:order-last',
                            )}"
                        />
                    `,
                )}
                <div class="md:w-1/2">
                    <h3 class="h4 mb-8">${page.title}</h3>
                    <div class="text-sm text-gray-50 mb-16">
                        ${page.authors[0].firstName} ${page.authors[0].lastName} -
                        ${formatDate(new Date(page.publicationDate), 'dd.MM.yyyy')}
                    </div>
                    <p class="mb-32 line-clamp-4">${page.introText}</p>
                    <a href="${page.link}" class="btn btn-outline-primary">${i18n('pageTeasers.continueReading')}</a>
                </div>
            </div>`,
            slider: html`<div class="slider-teaser border border-gray-20 w-full h-full flex flex-col">
                <img
                    src="${page.image ? page.image.link : '#'}"
                    alt="${page.image ? page.image.altText : ''}"
                    class="w-full aspect-video object-cover"
                />
                <div class="p-40 flex-1 flex flex-col justify-between">
                    <div>
                        <h3 class="h4 mb-16">${page.title}</h3>
                        <p class="mb-32 flex-1 line-clamp-4">${page.introText}</p>
                    </div>
                    <a href="${page.link}" class="block w-full btn btn-outline-primary"
                        >${i18n('pageTeasers.continueReading')}</a
                    >
                </div>
            </div>`,
        });
    }
}

defineElement('mb-page-teasers', PageTeasers);
